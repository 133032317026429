import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';

export const heading =
  'Reimagine your retirement wellness with a team of experts in your corner.';

export const ehealthExpertises = [
  `Nation’s Top Carriers ${FOOTNOTE_REFERENCES.doubleDagger}`,
  'Medicare Advantage Plans',
  'Medicare Supplemental Insurance',
  'Prescription Drug Coverage',
];

export const retirableExpertises = [
  'Retirement Planning & Investing',
  'Retirement Income',
  'High-Yield Savings',
  'Spending Plans',
];
