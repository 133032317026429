import React, { FC } from 'react';

import { MoreThanFinancialAdviceLarge } from './MoreThanFinancialAdviceLarge';
import { MoreThanFinancialAdviceSmall } from './MoreThanFinancialAdviceSmall';

export interface MoreThanFinancialAdviceProps {}

export const MoreThanFinancialAdvice: FC<MoreThanFinancialAdviceProps> = () => (
  <>
    <MoreThanFinancialAdviceLarge />
    <MoreThanFinancialAdviceSmall />
  </>
);
