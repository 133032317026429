import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { defaultInitialValues } from '../consultation-form/formUtils';
import { StyledFormLarge } from '../consultation-form/StyledFormLarge';
import { HeroProps } from '.';
import { body, heading, primaryCtaText } from './content';

const Wrapper = styled(ShowOn).attrs({
  screens: [Screen.Xxl, Screen.Xl],
})`
  max-width: 1488px;
  margin: 0 auto;
  padding-left: 40px;
`;

export const DesktopHero: FC<HeroProps> = () => (
  <Wrapper>
    <Grid cols={8} gap={1} spacing={1}>
      <GridColumn span={5}>
        <Heading variant={2} marginBottom={24} marginTop={32}>
          {heading}
        </Heading>
        <Body marginBottom={40} variant={1}>
          {body}
        </Body>
        <StyledFormLarge
          inModal={false}
          submitButtonText={primaryCtaText}
          initialValues={defaultInitialValues}
        />
      </GridColumn>
      <GridColumn span={3}>
        <StaticImage src="../images/happy-couple.webp" alt="hero" />
      </GridColumn>
    </Grid>
  </Wrapper>
);
