import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC } from 'react';
import styled from 'styled-components';

import { mostRetireesHeading, retirementAnxieties } from './content';
import { QuoteCard } from './QuoteCard';
import { typographyStyles, TypographyStylesProps } from 'component-library/components/typography/typographyStyles';

const Wrapper = styled(Flex)`
  max-width: 1488px;
  margin: 108px auto;
  padding: 0 40px;
`;

const HeaderWrapper = styled(Flex)`
  max-width: 560px;
`;

const Heading = styled.div<TypographyStylesProps>`
  font-family: 'Cooper';
  font-weight: 400;
  line-height: 120%;
  font-size: 65px;

  ${typographyStyles}
`;

const QuotesWrapper = styled(Flex)`
  width: 100%;
`;

interface LeftToNavigateLargeProps {}

export const LeftToNavigateLarge: FC<LeftToNavigateLargeProps> = () => (
  <ShowOn screens={[Screen.Xl, Screen.Xxl]}>
    <Wrapper alignItems="center">
      <HeaderWrapper>
        <Heading>{mostRetireesHeading}</Heading>
      </HeaderWrapper>
      <QuotesWrapper column>
        {retirementAnxieties.map((text, i) => (
          <QuoteCard key={i}>{text}</QuoteCard>
        ))}
      </QuotesWrapper>
    </Wrapper>
  </ShowOn>
);
