import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, ctaText, heading } from './content';
import { Step } from './Step';

const StepsWrapper = styled(Flex)`
  max-width: 1140px;
  margin: auto;
  background-color: ${colors.forestGreen};
  padding: 80px 20px;
`;

interface StepsSmallProps extends EhealthChildProps {}

export const StepsSmall: FC<StepsSmallProps> = ({ onConsultationClick }) => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <StepsWrapper column>
      <Heading color="white" variant={4} marginBottom={16}>
        {heading}
      </Heading>
      <Body color="white" variant={3} marginBottom={64}>
        {body}
      </Body>
      <Step stepNumber={1} marginBottom={80} />
      <Step stepNumber={2} marginBottom={80} />
      <Step stepNumber={3} marginBottom={80} />
      <Step stepNumber={4} marginBottom={8} />
      <Flex justifyContent="center">
        <PrimaryButton
          small
          buttonColor={ButtonColor.Peach}
          onClick={onConsultationClick}
          alignment="center"
          marginTop={48}
        >
          {ctaText}
        </PrimaryButton>
      </Flex>
    </StepsWrapper>
  </ShowOn>
);
