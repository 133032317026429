import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';

import { DesktopHeading } from './DesktopHeading';
import { MobileHeading } from './MobileHeading';

interface HeadingProps extends EhealthChildProps {}

export const Heading: FC<HeadingProps> = (props) => (
  <>
    <DesktopHeading {...props} />
    <MobileHeading {...props} />
  </>
);
