import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { defaultInitialValues } from '../consultation-form/formUtils';
import { StyledFormSmall } from '../consultation-form/StyledFormSmall';
import { HeroProps } from '.';
import { body, heading, primaryCtaText } from './content';

const ImageWrapper = styled(Flex)`
  padding-left: 20px;
`;

const ContentWrapper = styled(Flex)`
  padding: 0 20px;
`;

export const MobileHero: FC<HeroProps> = () => (
  <ShowOn screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <Flex column alignItems="center">
      <ImageWrapper>
        <StaticImage src="../images/happy-couple.webp" alt="hero" />
      </ImageWrapper>
      <ContentWrapper column>
        <Heading variant={4} marginBottom={24} marginTop={32}>
          {heading}
        </Heading>
        <Body variant={1} marginBottom={24}>
          {body}
        </Body>
        <StyledFormSmall
          initialValues={defaultInitialValues}
          inModal={false}
          submitButtonText={primaryCtaText}
        />
      </ContentWrapper>
    </Flex>
  </ShowOn>
);
