import {
  Header,
  Wrapper,
} from 'component-library/components/navigation/mobile/MobileNav';
import { Screen } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';

interface MobileHeadingProps extends EhealthChildProps {}

export const MobileHeading: FC<MobileHeadingProps> = () => (
  <Wrapper screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <Header alignItems="center" justifyContent="space-between">
      <StaticImage
        src="../images/ehealth-retirable-logos.webp"
        alt="partnership logo"
        width={300}
      />
    </Header>
  </Wrapper>
);
