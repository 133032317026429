import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelSmall } from '../shared/PanelSmall';
import { TrustedPartnerProps } from '.';
import { body, cta, heading } from './content';

export const TrustedPartnerSmall: FC<TrustedPartnerProps> = ({
  onConsultationClick,
}) => (
  <PanelSmall
    image={
      <StaticImage src="../images/trusted-partner.webp" alt="Couple biking" />
    }
    imageAlignment="below"
    body={body}
    heading={heading}
    buttonText={cta}
    onButtonClick={onConsultationClick}
  />
);
