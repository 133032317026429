import React, { FC } from 'react';

import { MoreFreedomLarge } from './MoreFreedomLarge';
import { MoreFreedomSmall } from './MoreFreedomSmall';

export interface MoreFreedomProps {}

export const MoreFreedom: FC<MoreFreedomProps> = () => (
  <>
    <MoreFreedomLarge />
    <MoreFreedomSmall />
  </>
);
