import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';

import { LeftToNavigateLarge } from './LeftToNavigateLarge';
import { LeftToNavigateSmall } from './LeftToNavigateSmall';

interface LeftToNavigateProps extends EhealthChildProps {}

export const LeftToNavigate: FC<LeftToNavigateProps> = (props) => (
  <>
    <LeftToNavigateLarge {...props} />
    <LeftToNavigateSmall {...props} />
  </>
);
