import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelSmall } from '../shared/PanelSmall';
import { MoreFreedomProps } from '.';
import { body, heading } from './content';

export const MoreFreedomSmall: FC<MoreFreedomProps> = () => (
  <PanelSmall
    image={
      <StaticImage src="../images/more-freedom.webp" alt="couple laughing" />
    }
    imageAlignment="below"
    body={body}
    heading={heading}
  />
);
