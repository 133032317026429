import React, { FC } from 'react';

import { DesktopHero } from './DesktopHero';
import { MobileHero } from './MobileHero';

export interface HeroProps {}

export const Hero: FC<HeroProps> = () => (
  <>
    <DesktopHero />
    <MobileHero />
  </>
);
