import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';

import { TeamExpertiseLarge } from './TeamExpertiseLarge';
import { TeamExpertiseSmall } from './TeamExpertiseSmall';

interface TeamExpertiseProps extends EhealthChildProps {}

export const TeamExpertise: FC<TeamExpertiseProps> = (props) => (
  <>
    <TeamExpertiseLarge {...props} />
    <TeamExpertiseSmall {...props} />
  </>
);
