import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ehealthExpertises, heading, retirableExpertises } from './content';

const Wrapper = styled(ShowOn).attrs({
  screens: [Screen.Xl, Screen.Xxl],
})`
  max-width: 1488px;
  margin: 108px auto;
  padding: 0 40px;
`;

const CardWrapper = styled(Flex)`
  max-width: 1256px;
  margin: auto;
`;

const LargeCard = styled(Flex)`
  background-color: ${colors.white};
  border-radius: 24px;
  border: 1px solid ${colors.navyBlue};
  max-width: 1360px;
  padding: 48px 240px 48px 24px;
  position: relative;
  box-shadow: -5px 5px black;
  gap: 24px;
`;

const LogoWrapper = styled.div`
  max-width: 242px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: -240px;
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  overflow: hidden;
`;

const ExpertiseWrapper = styled(Flex)`
  gap: 32px;
`;

export interface TeamExpertiseLargeProps {}

export const TeamExpertiseLarge: FC<TeamExpertiseLargeProps> = () => (
  <Wrapper>
    <Heading variant={3}>{heading}</Heading>
    <CardWrapper>
      <LargeCard
        justifyContent="space-around"
        alignItems="center"
        marginTop={80}
      >
        <ExpertiseWrapper column>
          <LogoWrapper>
            <StaticImage
              src="../images/ehealth-logo.webp"
              alt="eHealth logo"
              height={44}
            />
          </LogoWrapper>
          {ehealthExpertises.map((expertise, idx) => (
            <Flex alignItems="center" key={idx}>
              <StyledCheckmark />
              <Body variant={1}>{expertise}</Body>
            </Flex>
          ))}
        </ExpertiseWrapper>
        <ExpertiseWrapper column>
          <LogoWrapper>
            <StaticImage
              src="../images/retirable-logo.webp"
              alt="retirable logo"
              height={44}
            />
          </LogoWrapper>
          {retirableExpertises.map((expertise, idx) => (
            <Flex alignItems="center" key={idx}>
              <StyledCheckmark />
              <Body variant={1}>{expertise}</Body>
            </Flex>
          ))}
        </ExpertiseWrapper>
        <ImageWrapper>
          <StaticImage src="../images/woman-jogging.webp" alt="woman jogging" />
        </ImageWrapper>
      </LargeCard>
    </CardWrapper>
  </Wrapper>
);
