import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelSmall } from '../shared/PanelSmall';
import { OneStepCloserProps } from '.';
import { body, heading } from './content';

export const OneStepCloserSmall: FC<OneStepCloserProps> = () => (
  <PanelSmall
    image={
      <StaticImage
        src="../images/one-step-closer.webp"
        alt="Man with his feet up"
      />
    }
    imageAlignment="below"
    body={body}
    heading={heading}
  />
);
