import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import {
  FormikPhoneInput,
  FormikTextInput,
} from 'component-library/components/form';
import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  ConsultationFormData,
  ConsultationFormSchema,
  submitForm,
} from './formUtils';

const StyledForm = styled(Form)`
  width: 100%;
`;

const TwoFieldWrapper = styled(Flex)`
  gap: 24px;
  > div {
    flex-grow: 1;
  }
`;

interface StyledFormLargeProps {
  initialValues: ConsultationFormData;
  inModal: boolean;
  submitButtonText?: string;
}

export const StyledFormLarge: FC<StyledFormLargeProps> = ({
  initialValues,
  inModal,
  submitButtonText,
}) => (
  <ShowOn screens={[Screen.Xl, Screen.Xxl]}>
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => submitForm(data, inModal)}
      validationSchema={ConsultationFormSchema}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <Flex column>
            <TwoFieldWrapper>
              <Field
                name="firstName"
                placeholder="First Name"
                component={FormikTextInput}
                inputWidth={4}
                disabled={isSubmitting}
              />
              <Field
                name="lastName"
                placeholder="Last Name"
                component={FormikTextInput}
                inputWidth={4}
                disabled={isSubmitting}
              />
            </TwoFieldWrapper>
            <Field
              name="email"
              inputMode="email"
              placeholder="Email"
              component={FormikTextInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <Field
              name="phone"
              placeholder="Phone"
              component={FormikPhoneInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <PrimaryButton
              fullWidth
              buttonColor={ButtonColor.Peach}
              disabled={isSubmitting}
              alignment="center"
            >
              {submitButtonText || 'Submit'}
            </PrimaryButton>
          </Flex>
        </StyledForm>
      )}
    </Formik>
  </ShowOn>
);
