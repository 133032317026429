import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ehealthExpertises, heading, retirableExpertises } from './content';

const Wrapper = styled(ShowOn).attrs({
  screens: [Screen.Sm, Screen.Md, Screen.Lg],
})`
  margin-top: 108px;
  padding: 0 16px;
`;

const LargeCard = styled(Flex)`
  max-width: calc(100% - 32px);
  margin: 24px auto;
  background-color: ${colors.white};
  border-radius: 24px;
  border: 1px solid ${colors.navyBlue};
  padding: 24px;
  position: relative;
  box-shadow: -5px 5px black;
`;

const LogoWrapper = styled.div`
  max-width: 242px;
`;

const ExpertiseWrapper = styled(Flex)`
  gap: 32px;
`;

export interface TeamExpertiseSmallProps {}

export const TeamExpertiseSmall: FC<TeamExpertiseSmallProps> = () => (
  <Wrapper>
    <Heading variant={4}>{heading}</Heading>
    <LargeCard
      column
      justifyContent="space-around"
      alignItems="center"
      marginTop={80}
    >
      <ExpertiseWrapper column>
        <LogoWrapper>
          <StaticImage
            src="../images/ehealth-logo.webp"
            alt="eHealth logo"
            height={40}
          />
        </LogoWrapper>
        <Flex column>
          {ehealthExpertises.map((expertise, idx) => (
            <Flex alignItems="center" key={idx}>
              <StyledCheckmark />
              <Body variant={1}>{expertise}</Body>
            </Flex>
          ))}
        </Flex>
      </ExpertiseWrapper>
      <ExpertiseWrapper column marginTop={32}>
        <LogoWrapper>
          <StaticImage
            src="../images/retirable-logo.webp"
            alt="retirable logo"
            height={40}
          />
        </LogoWrapper>
        <Flex column>
          {retirableExpertises.map((expertise, idx) => (
            <Flex alignItems="center" key={idx}>
              <StyledCheckmark />
              <Body variant={1}>{expertise}</Body>
            </Flex>
          ))}
        </Flex>
      </ExpertiseWrapper>
    </LargeCard>
  </Wrapper>
);
