import { Flex } from 'component-library/components/layout/Flex';
import { bodyStyles } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import styled from 'styled-components';

export const QuoteCard = styled(Flex).attrs({
  variant: 1,
  marginTop: 24,
})`
  background-color: ${colors.white};
  border-radius: 24px;
  border: 1px solid ${colors.navyBlue};
  width: 100%;
  padding: 16px;
  box-shadow: -5px 5px black;

  ${bodyStyles}
`;
