import React, { FC } from 'react';

import { StartsWithAPlanLarge } from './StartsWithAPlanLarge';
import { StartsWithAPlanSmall } from './StartsWithAPlanSmall';

export interface StartsWithAPlanProps {}

export const StartsWithAPlan: FC<StartsWithAPlanProps> = () => (
  <>
    <StartsWithAPlanSmall />
    <StartsWithAPlanLarge />
  </>
);
