import axios from 'axios';
import {
  emailValidation,
  firstNameValidation,
  phoneValidation,
} from 'component-library/components/form/fields';
import { track } from 'utils/analytics';
import * as yup from 'yup';

import { MODAL_ID } from './ConsultationModal';

export interface ConsultationFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export const defaultInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

export interface ConsultationFormSubmitPayload extends ConsultationFormData {
  source: string;
  medium: string;
  term: string;
  campaign: string;
}

export const ConsultationFormSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: yup
    .string()
    .required('Last name is required')
    .typeError('Please enter your last name'),
  phone: phoneValidation,
  email: emailValidation,
});

export const submitForm = async (
  data: ConsultationFormData,
  inModal: boolean,
) => {
  const payload: ConsultationFormSubmitPayload = {
    ...data,
    source: 'medicare',
    medium: 'landing page',
    term: 'ehealth',
    campaign: 'ehealth',
  };

  if (inModal) {
    track('Modal Form Submitted', { modalId: MODAL_ID });
  } else {
    track('Inline Form Submitted', { formId: 'ehealth-consultation-form' });
  }

  await axios.request({
    method: 'post',
    url: '/api/createMedicareLead',
    data: payload,
  });
  onSuccess(payload);
};

const onSuccess = (data: ConsultationFormSubmitPayload) => {
  const params = new URLSearchParams({
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phone,
    email: data.email,
    medium: data.medium,
    campaign: data.campaign,
    term: data.term,
  });
  window.location.href = `${process.env.GATSBY_HOWDY_BASE_URL}/partners/${
    data.campaign
  }/appointments/new?${params.toString()}`;
};
