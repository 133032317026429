import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelSmall } from '../shared/PanelSmall';
import { PeaceOfMindProps } from '.';
import { cta, heading, valueProps } from './content';

export const PeaceOfMindSmall: FC<PeaceOfMindProps> = ({
  onConsultationClick,
}) => (
  <PanelSmall
    image={<StaticImage src="../images/peace-of-mind.webp" alt="Budget chart" />}
    imageAlignment="below"
    heading={heading}
    valueProps={valueProps}
    buttonText={cta}
    onButtonClick={onConsultationClick}
  />
);
