import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelLarge } from '../shared/PanelLarge';
import { MoreThanFinancialAdviceProps } from '.';
import { body, heading, valueProps } from './content';

export const MoreThanFinancialAdviceLarge: FC<MoreThanFinancialAdviceProps> = () => (
  <PanelLarge
    image={
      <StaticImage
        src="../images/more-than-financial-advice.webp"
        alt="Three generations on shoulders"
      />
    }
    imageAlignment="right"
    body={body}
    heading={heading}
    valueProps={valueProps}
    spacing={48}
  />
);
