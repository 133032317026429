import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelLarge } from '../shared/PanelLarge';
import { StartsWithAPlanProps } from '.';
import { body, heading } from './content';

export const StartsWithAPlanLarge: FC<StartsWithAPlanProps> = () => (
  <PanelLarge
    image={
      <StaticImage src="../images/starts-with-a-plan.webp" alt="Man at a desk" />
    }
    imageAlignment="left"
    body={body}
    heading={heading}
    spacing={48}
  />
);
