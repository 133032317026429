import { EhealthChildProps } from 'pages/partners/ehealth';
import React, { FC } from 'react';

import { PeaceOfMindLarge } from './PeaceOfMindLarge';
import { PeaceOfMindSmall } from './PeaceOfMindSmall';

export interface PeaceOfMindProps extends EhealthChildProps {}

export const PeaceOfMind: FC<PeaceOfMindProps> = ({ ...childProps }) => (
  <div id="peace-of-mind">
    <PeaceOfMindLarge {...childProps} />
    <PeaceOfMindSmall {...childProps} />
  </div>
);
