import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { PanelLarge } from '../shared/PanelLarge';
import { PeaceOfMindProps } from '.';
import { cta, heading, valueProps } from './content';

export const PeaceOfMindLarge: FC<PeaceOfMindProps> = ({
  onConsultationClick,
}) => (
  <PanelLarge
    image={<StaticImage src="../images/peace-of-mind.webp" alt="Budget chart" />}
    imageAlignment="right"
    heading={heading}
    valueProps={valueProps}
    buttonText={cta}
    onButtonClick={onConsultationClick}
    spacing={72}
    textMaxWidth={640}
  />
);
