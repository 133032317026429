import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC } from 'react';
import styled from 'styled-components';

import { mostRetireesHeading, retirementAnxieties } from './content';
import { QuoteCard } from './QuoteCard';

const Wrapper = styled(Flex)`
  padding: 0 24px;
  margin: 108px auto;
`;

const HeaderWrapper = styled(Flex).attrs({
  variant: 4,
})``;

const QuotesWrapper = styled(Flex)``;

export interface LeftToNavigateSmallProps {}

export const LeftToNavigateSmall: FC<LeftToNavigateSmallProps> = () => (
  <ShowOn screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <Wrapper column>
      <HeaderWrapper>
        <Heading variant={4}>{mostRetireesHeading}</Heading>
      </HeaderWrapper>
      <QuotesWrapper column>
        {retirementAnxieties.map((text, i) => (
          <QuoteCard key={i}>{text}</QuoteCard>
        ))}
      </QuotesWrapper>
    </Wrapper>
  </ShowOn>
);
