import React, { FC } from 'react';

import { OneStepCloserLarge } from './OneStepCloserLarge';
import { OneStepCloserSmall } from './OneStepCloserSmall';

export interface OneStepCloserProps {}

export const OneStepCloser: FC<OneStepCloserProps> = () => (
  <>
    <OneStepCloserSmall />
    <OneStepCloserLarge />
  </>
);
