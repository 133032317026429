import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Link } from 'component-library/components/links/Link';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { ConsultationModal } from 'page-components/partnerships/deprecated/ehealth/consultation-form/ConsultationModal';
import { Heading } from 'page-components/partnerships/deprecated/ehealth/heading';
import { Hero } from 'page-components/partnerships/deprecated/ehealth/hero';
import { LeftToNavigate } from 'page-components/partnerships/deprecated/ehealth/left-to-navigate';
import { RetirableProcess } from 'page-components/partnerships/deprecated/ehealth/retirable-process';
import { Steps } from 'page-components/partnerships/deprecated/ehealth/steps';
import { TeamExpertise } from 'page-components/partnerships/deprecated/ehealth/team-expertise';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';
import { Footer } from 'page-components/shared';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';
import React, { FC } from 'react';

interface EhealthProps {}

export interface EhealthChildProps {
  onConsultationClick: () => void;
}

const Ehealth: FC<EhealthProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleConsultationClick = () => {
    setIsModalOpen(true);
  };

  const handleConsultationClose = () => {
    setIsModalOpen(false);
  };

  const childProps = {
    onConsultationClick: handleConsultationClick,
  };

  return (
    <Layout>
      <Heading {...childProps} />
      <PageWrapper background={colors.greenLight}>
        {isModalOpen && <ConsultationModal onClose={handleConsultationClose} />}
        <Hero {...childProps} />
        <TeamExpertise {...childProps} />
        <LeftToNavigate {...childProps} />
        <RetirableProcess {...childProps} />
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <Steps {...childProps} />
        <Footer
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor('eHealth'),
            <>
              {FOOTNOTE_REFERENCES.dagger} eHealth will receive compensation if
              you enter into a paying relationship with Retirable. You will not
              be charged any fee or incur any additional costs for being
              referred by eHealth. Retirable and eHealth are not under common
              ownership or otherwise related entities.
            </>,
            <>
              {FOOTNOTE_REFERENCES.doubleDagger} Top plans based on KFF&apos;s
              2023 Update and Key Trends report; analysis of 2022 CMS Medicare
              Advantage enrollment by firm.”{' '}
              <Link
                to="https://www.kff.org/medicare/issue-brief/medicare-advantage-in-2023-enrollment-update-and-key-trends/"
                variant={5}
                inline
              >
                Link
              </Link>
            </>,
          ]}
        />
      </PageWrapper>
    </Layout>
  );
};

export default Ehealth;

export const Head = () => (
  <SEO
    title="eHealth + Retirable"
    description="Learn more about how eHealth and Retirable are working together to help you have peace of mind in retirement."
  />
);
